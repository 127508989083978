.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;

  &-inner {
    background: lighten($color-bg-body, 5);
    padding: 100px 50px;
    width: 100%;
  }

  h1 {
    margin-top: -20px;
    font-size: 12.5rem;
    line-height: 1;
    font-weight: 700;
    color: #fd7171;
  }

  h3 {
    margin-top: -10px;
  }

  .mi-button {
    margin-top: 20px;
  }

  @media #{$layout-tablet} {
    h1 {
      font-size: 10.5rem;
    }
  }

  @media #{$layout-mobile-lg} {
    &-inner{
      padding: 75px 15px;
    }

    h1 {
      margin-top: -15px;
      font-size: 8.5rem;
    }
  }

  @media #{$layout-mobile-lg} {
    &-inner{
      padding: 65px 15px;
    }

    h1 {
      font-size: 7.5rem;
    }
  }
}